<template>
  <CLoading :loading="loading" />
  <div class="row items-center q-gutter-y-sm q-my-md">
    <h1 class="text-h4 text-weight-medium">{{ $t('label.apps_management') }}</h1>
    <q-space></q-space>
    <div class="row items-center q-gutter-sm">
      <q-input outlined v-model="searchQuery" dense :placeholder="$t('label.app_name')">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>

      <q-btn no-caps color="positive" class="q-ml-sm" icon="settings" @click="onGotoLineApp">
        {{ $t('label.app.LINE_developer_console') }}
      </q-btn>
      <q-btn
        v-if="userInfo?.canWriteApp"
        no-caps
        color="primary"
        class="q-ml-sm"
        icon="add"
        @click.prevent="modalVisible = true"
      >
        {{ $t('label.app.new_app') }}
      </q-btn>

      <download-csv
        class="hidden"
        name="Report.csv"
        :data="jsonData"
        :fields="csvHeader.fields"
        :labels="csvHeader.labels"
        @update:exportFinished="exportFinished"
        ref="downloadCSV"
      >
        {{ $t('label.export_csv') }}</download-csv
      >
    </div>
  </div>

  <q-space class="q-my-md" />
  <div class="row items-start q-gutter-sm q-mt-lg">
    <q-btn no-caps color="primary" class="q-ml-sm" icon="download" @click="onExportChannels()" v-if="userInfo?.isSuper">
      {{ $t('label.export_csv') }}
    </q-btn>
  </div>
  <div class="row items-start q-mt-sm">
    <q-card bordered class="app-card q-mr-sm q-mb-sm" v-for="app in appsFiltered" :key="app._id">
      <router-link class="text-h6" :to="{ name: 'overview', params: { app_id: app._id } }">
        <q-card-section class="cursor-pointer app-card__title">
          {{ app.name }}
        </q-card-section>
        <q-card-section class="app-card__subtitle">
          <div class="text-subtitle2 text-grey-6" v-if="app.bot_name">{{ app.bot_name }}</div>
          <div class="text-subtitle2 text-grey-6">
            {{ $t('label.app.updated') }} {{ formatDateTime(app.updated_at) }}
          </div>
        </q-card-section>
      </router-link>
      <q-card-actions class="app-card__actions">
        <div class="q-gutter-sm">
          <q-btn
            size="sm"
            padding="sm"
            round
            outline
            icon="settings"
            @click="onPermission(app)"
            v-if="userInfo?.isSuper"
          />
          <q-btn size="sm" padding="sm" round outline icon="edit" @click="onEdit(app)" />
          <q-btn size="sm" padding="sm" outline round icon="content_copy" @click="onCopy(app)" />
          <q-btn
            v-if="userInfo?.canWriteApp"
            size="sm"
            padding="sm"
            color="red"
            round
            outline
            icon="delete"
            @click="onDelete(app)"
          />
        </div>
      </q-card-actions>
    </q-card>
  </div>

  <div class="text-grey-6 text-subtitle2 q-py-lg text-center" v-if="!appsFiltered.length">
    {{ $t('label.app.no_apps') }}
  </div>

  <AppPermissionModal
    v-if="permissionModalVisible"
    :modalVisible="permissionModalVisible"
    :appId="selectedApp._id"
    @update:closeModal="onClosePermissionModal"
  />

  <AppFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedApp"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import { CSVHeader } from '@/utils/csv'
import { formatDateTime, onGotoLineApp } from '@/utils/helpers'
import AppFormModal from '@/components/app/AppFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import { UserModel } from '@/models/user-model'
import CLoading from '@/components/common/ui/CLoading.vue'
import JsonCSV from '@/components/common/ui/JsonCSV.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import AppPermissionModal from '@/components/app/AppPermissionModal.vue'

@Options({
  components: {
    AppPermissionModal,
    AppFormModal,
    CLoading,
    'download-csv': JsonCSV,
  },
  directives: { maska },
})
export default class Apps extends mixins(AppMixin, BaseFormMixin) {
  selectedApp: IApp = {}
  modalVisible = false
  permissionModalVisible = false

  searchQuery = ''
  loading = true

  formatDateTime = formatDateTime
  onGotoLineApp = onGotoLineApp

  isExported = false
  // Download CSV header
  csvHeader!: CSVHeader
  // jsonData: { created_at?: string }[] = []
  jsonData = [{}]

  get apps(): IApp[] {
    return this.$store.getters.apps || []
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get appsFiltered() {
    const copyApps = cloneDeep(this.apps)
    return copyApps.filter((item) => {
      const toSearch = `${item.name}`.toLowerCase()
      const keyword = this.searchQuery.toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0
      return match1
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onAdd() {
    this.selectedApp = {}
    this.modalVisible = true
  }

  async onCopy(data: IApp) {
    this.modalVisible = true
    const copy: IApp = cloneDeep(data)
    copy._id = ''
    copy.name = copy.name + ' Copy'
    copy.token = ''
    copy.secret = ''
    copy.token_test = copy.token
    copy.secret_test = copy.secret
    copy.account_id = ''
    copy.account_id_test = ''
    copy.liff_id = ''
    copy.liff_id_test = ''
    copy.copy_app_id = data._id
    copy.user_id = this.userInfo._id
    this.selectedApp = copy
  }

  onEdit(data: IApp) {
    this.selectedApp = data
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedApp = {}
  }

  onPermission(data: IApp) {
    this.selectedApp = data
    this.permissionModalVisible = true
  }

  onClosePermissionModal() {
    this.permissionModalVisible = false
    this.selectedApp = {}
  }

  async created() {
    // Download CSV header
    this.csvHeader = new CSVHeader({
      created_at: this.$t('label.created_at'),
      last_update: this.$t('label.last_update'),
      account_name: this.$t('label.account_name'),
      line_channel_name: this.$t('label.line_channel_name'),
      users_mail_address: this.$t('label.users_mail_address'),
      num_of_initial_message_campaigns: this.$t('label.num_of_initial_message_campaigns'),
      num_of_push_message_campaigns: this.$t('label.num_of_push_message_campaigns'),
      num_of_push_popup_campaigns: this.$t('label.num_of_push_popup_campaigns'),
      num_of_push_audience_list: this.$t('label.num_of_push_audience_list'),
      num_of_activeline_users: this.$t('label.num_of_activeline_users'),
      num_of_blocked_line_users: this.$t('label.num_of_blocked_line_users'),
      num_of_total_line_users: this.$t('label.num_of_total_line_users'),
    })

    await this.$store.dispatch(ACTION_APP.LOAD_ITEMS)
    this.loading = false
  }

  async onExportChannels() {
    this.loading = true
    const channels = await this.$store.dispatch(ACTION_APP.EXPORT_CHANNELS)

    this.jsonData = channels
    this.$nextTick(() => {
      this.$refs.downloadCSV.generate()
    })
  }

  exportFinished() {
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
.app-card {
  width: 100%;
  max-width: 320px;
  min-height: 160px;
  &.q-card--bordered {
    border-color: #9aa2fc;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  }
  &:hover {
    box-shadow: 0 0 3px $primary;
  }
  &__title {
    padding-bottom: 0;
  }
  &__subtitle {
    padding-top: 4px;
    padding-bottom: 0;
  }
  &__actions {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
</style>
