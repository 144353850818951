
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import { CSVHeader } from '@/utils/csv'
import { formatDateTime, onGotoLineApp } from '@/utils/helpers'
import AppFormModal from '@/components/app/AppFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import { UserModel } from '@/models/user-model'
import CLoading from '@/components/common/ui/CLoading.vue'
import JsonCSV from '@/components/common/ui/JsonCSV.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import AppPermissionModal from '@/components/app/AppPermissionModal.vue'

@Options({
  components: {
    AppPermissionModal,
    AppFormModal,
    CLoading,
    'download-csv': JsonCSV,
  },
  directives: { maska },
})
export default class Apps extends mixins(AppMixin, BaseFormMixin) {
  selectedApp: IApp = {}
  modalVisible = false
  permissionModalVisible = false

  searchQuery = ''
  loading = true

  formatDateTime = formatDateTime
  onGotoLineApp = onGotoLineApp

  isExported = false
  // Download CSV header
  csvHeader!: CSVHeader
  // jsonData: { created_at?: string }[] = []
  jsonData = [{}]

  get apps(): IApp[] {
    return this.$store.getters.apps || []
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get appsFiltered() {
    const copyApps = cloneDeep(this.apps)
    return copyApps.filter((item) => {
      const toSearch = `${item.name}`.toLowerCase()
      const keyword = this.searchQuery.toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0
      return match1
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onAdd() {
    this.selectedApp = {}
    this.modalVisible = true
  }

  async onCopy(data: IApp) {
    this.modalVisible = true
    const copy: IApp = cloneDeep(data)
    copy._id = ''
    copy.name = copy.name + ' Copy'
    copy.token = ''
    copy.secret = ''
    copy.token_test = copy.token
    copy.secret_test = copy.secret
    copy.account_id = ''
    copy.account_id_test = ''
    copy.liff_id = ''
    copy.liff_id_test = ''
    copy.copy_app_id = data._id
    copy.user_id = this.userInfo._id
    this.selectedApp = copy
  }

  onEdit(data: IApp) {
    this.selectedApp = data
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedApp = {}
  }

  onPermission(data: IApp) {
    this.selectedApp = data
    this.permissionModalVisible = true
  }

  onClosePermissionModal() {
    this.permissionModalVisible = false
    this.selectedApp = {}
  }

  async created() {
    // Download CSV header
    this.csvHeader = new CSVHeader({
      created_at: this.$t('label.created_at'),
      last_update: this.$t('label.last_update'),
      account_name: this.$t('label.account_name'),
      line_channel_name: this.$t('label.line_channel_name'),
      users_mail_address: this.$t('label.users_mail_address'),
      num_of_initial_message_campaigns: this.$t('label.num_of_initial_message_campaigns'),
      num_of_push_message_campaigns: this.$t('label.num_of_push_message_campaigns'),
      num_of_push_popup_campaigns: this.$t('label.num_of_push_popup_campaigns'),
      num_of_push_audience_list: this.$t('label.num_of_push_audience_list'),
      num_of_activeline_users: this.$t('label.num_of_activeline_users'),
      num_of_blocked_line_users: this.$t('label.num_of_blocked_line_users'),
      num_of_total_line_users: this.$t('label.num_of_total_line_users'),
    })

    await this.$store.dispatch(ACTION_APP.LOAD_ITEMS)
    this.loading = false
  }

  async onExportChannels() {
    this.loading = true
    const channels = await this.$store.dispatch(ACTION_APP.EXPORT_CHANNELS)

    this.jsonData = channels
    this.$nextTick(() => {
      this.$refs.downloadCSV.generate()
    })
  }

  exportFinished() {
    this.loading = false
  }
}
